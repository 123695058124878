import { Trans, t } from "@lingui/macro";
import Home from "@mui/icons-material/Home";
import Logout from "@mui/icons-material/Logout";
import { useRouter } from "next/router";
import { FunctionComponent, PropsWithChildren, useRef } from "react";
import { logoutCallback } from "../../api-hooks/auth/logoutCallback";
import { getLoggedInPath } from "../../api-hooks/auth/useAuthentication";
import { UserType, useUserDetails } from "../../api-hooks/auth/useUserDetails";
import { PageError } from "../../components/ClientApp/shared/PageError";
import { AppLoadingSpinner } from "../../components/ClientApp/shared/PageLoading";
import { EulaSigner } from "../../components/Eula/EulaSigner";
import { button } from "../../styles";
import { InlineNotification } from "../InlineNotification/InlineNotification";
import { useEventTracking } from "../analytics/useEventTracking";
import style from "./Authorized.module.css";

export const Authorized: FunctionComponent<
  PropsWithChildren<{
    allowedUserTypes: UserType[];
    hideMessage: boolean;
  }>
> = ({ children, allowedUserTypes, hideMessage = false }) => {
  const router = useRouter();

  const { trackCriticalError } = useEventTracking();

  const userDetails = useUserDetails();

  const isActiveUser = userDetails.data?.userStatus === "Active";

  const hasActiveAccount = userDetails.data?.accountStatus === "Active";

  const alertIssued = useRef(false);

  if (userDetails.status === "success" && isActiveUser) {
    if (!hasActiveAccount && !alertIssued.current) {
      trackCriticalError(
        "Inactive User Login",
        "AUTHENTICATION",
        `${userDetails.data.givenName} ${userDetails.data.familyName} (${userDetails.data.uniqueName}) Logged in as an ${userDetails.data.userStatus} user with an ${userDetails.data.accountStatus} ${userDetails.data.accountType} account.`,
      );
      alertIssued.current = true;
    }

    const isUserTypeAllowed = allowedUserTypes.includes(
      userDetails.data.userType,
    );

    if (userDetails.data.outstandingEulas !== undefined) {
      return (
        <EulaSigner
          documents={userDetails.data.outstandingEulas}
          URLs={userDetails.data.outstandingEulaUrls}
        />
      );
    }

    if (isUserTypeAllowed) {
      return <>{children}</>;
    }
    if (!hideMessage) {
      return (
        <div className={style.main}>
          <div className={style.innerMain}>
            <InlineNotification
              type="error"
              message={t`You are unauthorized to view this page.`}
            />
            <div className={button.buttonRow}>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  router.push(
                    userDetails
                      ? getLoggedInPath(userDetails.data.userType)
                      : "/",
                    undefined,
                    {
                      shallow: true,
                    },
                  );
                }}
                className={button.buttonSecondary}
              >
                <Home /> Home
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  if (hideMessage) {
    return <></>;
  }

  if (userDetails.status === "success" && !isActiveUser) {
    const errorMessage = t`We can't find an active account for this email.  For additional information, please contact us at contact@egoscue.com.`;

    return (
      <div className={style.main}>
        <div className={style.innerMain}>
          <InlineNotification
            type="error"
            message={errorMessage}
            textDisplayOption="unbound"
          />
          <button
            className={button.buttonPrimary}
            type="button"
            onClick={() => {
              logoutCallback();
            }}
          >
            <Logout /> <Trans>Log out</Trans>
          </button>
        </div>
      </div>
    );
  }

  if (userDetails.status === "error") {
    <PageError />;
  }

  return (
    <div className={style.loadingContainer}>
      <AppLoadingSpinner />
    </div>
  );
};
